<template>
  <div @click="goTo('/')" class="logos d-flex justify-center cursor-pointer  align-center ">
    <div class="">
      <img :src="require('@/assets/logo.png')" alt=""/>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goTo(link) {
      this.$router.push(link);
    },
  },
};
</script>

<style>
.logos {
  width: 9rem;
  /* height: 8rem; */
  padding: 0;
  margin: 0;
}
.logos img{
  width: 100%;

  height: 100%;
  /* height: 2rem; */
}

.logo span {
  /* font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: top 0.5s; */
}
</style>