<template>
  <v-app id="App">

    <v-navigation-drawer v-model="drawer" temporary absolute>
      <div class="">
        <div class="px-2 py-md-5 py-3 d-flex justify-space-between">
          <div>
            <logo />
          </div>
          <v-btn @click="toggleDrawer" icon color="icon">
            <v-icon size="1.5rem">mdi-close</v-icon></v-btn>
        </div>
      </div>
      <v-divider></v-divider>
      <div>
        <div class="px-2 py-md-5 py-3  d-flex justify-spce-between align-center">
          <div class="mr-2">
            <h3 class="userName secondary--text">
              {{ userProfile.lastName }} {{ userProfile.firstName }}
            </h3>
          </div>
          <v-avatar class=" d-md-block">
            <img v-if="userProfile.avatar" :src="userProfile.avatar" class="photo" />
            <div v-else>
              <v-icon size="3rem">mdi-account-circle</v-icon>
            </div>
          </v-avatar>

         
        </div>
      </div>
      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in navs" :key="item.title" link @click="goTo(item.link)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="icon"></v-divider>
    </v-navigation-drawer>


    <v-app-bar app elevation="0" color="white" class="my-0 py-0" height="100rem">
      <div class="">
        <div class="px-2 py-md-5 py-3 d-flex mr-1">
          <v-btn @click="toggleDrawer" color="secondary" class="btn" icon>
            <v-icon size="1.8rem" color="icon" class="m-icon">mdi-menu</v-icon>
          </v-btn>
          <div class="d-flex align-center">
            <logo />
          </div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="d-md-block d-none ">
        <div>
          <v-badge :content="Number(userShopingCart.countItem)" :values="Number(userShopingCart.countItem)"
            :color="userShopingCart.countItem > 0 ? 'green' : 'white'" overlap>
            <v-btn icon @click="goTo('/shopingcart')">
              <v-icon large>mdi-cart</v-icon>
            </v-btn>
          </v-badge>
        </div>
        <div v-if="false" class="search-group ">
          <svg class="search-icon" aria-hidden="true" viewBox="0 0 24 24">
            <g>
              <path
                d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z">
              </path>
            </g>
          </svg>
          <input placeholder="Search" type="search" class="search-input" />
        </div>
      </div>
      <div class="d-none d-md-block ml-5 px-3 py-md-5 py-3">
        <button v-if="!activesSubscription" @click="goTo('/pricing')" class="m-fixbtn px-2">
          <span>
            <v-icon size="16px" color="white" class="mr-1">mdi-star-shooting</v-icon>Upgrade your account
          </span>
        </button>
        <button v-if="activesSubscription" @click="goTo('/active-subscription')" class="m-fixbtn px-2">
          <span>
            <v-icon size="16px" color="white" class="mr-1">mdi-star-shooting</v-icon> {{ activesSubscription.plan }}
            plan
          </span>
        </button>
      </div>
      <div class="d-none ml-5 px-2 py-md-5 py-3">
        <button @click="addProject" class="m-btn">
          <span>
            <v-icon size="16px" color="green" class="mr-1">mdi-plus</v-icon>Add
            Project
          </span>
        </button>
      </div>
      <div class="px-2 py-md-5 py-3">
        <div class="d-flex align-center">
          <v-avatar class="d-none d-md-block">
            <img v-if="userProfile.avatar" :src="userProfile.avatar" class="photo" />
            <div v-else>
              <v-icon size="3rem">mdi-account-circle</v-icon>
            </div>
          </v-avatar>
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text class="ml-2 btn" v-bind="attrs" v-on="on" rounded>
                <h3 class="userName secondary--text">
                  {{ userProfile.lastName }} {{ userProfile.firstName }}
                  <v-icon>mdi-chevron-down</v-icon>
                </h3>
              </v-btn>
            </template>

            <v-card>
              <div class="m-card white">
                <div class="m-card-top-part">
                  <div class="m-left-part">
                    <div class="m-user-name">
                      <p class="m-name">
                        {{ userProfile.lastName }} {{ userProfile.firstName }}
                      </p>
                      <p class="m-role">Certified</p>
                    </div>
                    <div class="m-user-position">
                      <v-list-item-subtitle class="secondary--text">{{
                        userProfile.username
                      }}</v-list-item-subtitle>
                    </div>
                  </div>
                  <div class="right-part">
                    <v-avatar>
                      <img v-if="userProfile.avatar" :src="userProfile.avatar" class="photo" />
                      <div v-else>
                        <v-icon size="3rem">mdi-account-circle</v-icon>
                      </div>
                    </v-avatar>
                  </div>
                </div>
                <div class="m-card-bottom-part">
                  <div class="bottom-part">
                    <v-btn class="btn" text @click="manageShowProfile"><v-icon class="mr-2">mdi-account</v-icon>
                      Profile</v-btn>
                  </div>
                  <div class="bottom-part">
                    <v-btn class="btn" text @click="signOut"><v-icon class="mr-2"
                        color="red">mdi-logout-variant</v-icon>
                      Logout</v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="dash  px-1  mt-md-3 mt-lg-0">
      <!-- Provides the application the proper gutter -->
      <v-container class="" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>

    <div>
      <!-- profile -->
      <!-- <v-dialog v-model="profileDialog" max-width="40rem">
        <editor-x
          @success="onSuccess"
          :fields="profileFields"
          :callback="callBackProfile"
          :model="profileModel"
          title="Profile"
          :withoutImages="false"
          v-if="profileDialog"
          submitText="Update profile"
        />
      </v-dialog> -->

      <v-dialog v-model="showAddProject" max-width="50rem">
        <editor-x @success="onSuccess" :fields="projectField" :callback="callBackProject" :model="projectModel"
          title="Project" :withoutImages="false" v-if="showAddProject" submitText="Create Project" />
      </v-dialog>

      <show-profile @success="onSuccess" :dialog="profileDialog" @dialogChange="manageShowProfile"
        :profileModel="profileModel" />
    </div>
  </v-app>
</template>

<script>
import { logout } from "@/api/user";
import { methods, rules } from "@/utils/constant.js";
import { createProject, updateProfile } from "@/api/user.js";
import { mapGetters, mapActions } from "vuex";
import Logo from "../components/user/Logo.vue";
import EditorX from "../components/universal/EditorX.vue";
import ShowProfile from "../components/user/ShowProfile.vue";



export default {
  components: { Logo, EditorX, ShowProfile },
  data() {
    return {
      drawer: false,
      profileDialog: false,
      menu: false,
      profileModal: {},
      loading: false,
      showAddProject: false,
      callBackProject: null,
      callBackProfile: null,
      alreadyReminderUserAboutGitLab: false,
      projectModel: {},
      profileModel: {},
      navs: [
        { title: 'Home', icon: 'mdi-home', link: "/home" },
        { title: 'Active Subscription', icon: 'mdi-star-shooting', link: "/active-subscription" },
        { title: 'Shoping Cart', icon: 'mdi-cart', link: "/shopingcart" },
        { title: 'Pricing', icon: 'mdi-cash-multiple', link: "/pricing" },
        // { title: 'Subscription', icon: 'mdi-star-shooting',link:"/subscription" },
        // { title: 'About', icon: 'mdi-help-box' },
      ],

      accessToken: 'your_access_token',
      serverResponse: ''
    };
  },
  computed: {
    ...mapGetters(["userProfile", "workspaces", "userShopingCart", "activesSubscription"]),
    projectField() {
      return [
        {
          name: "name",
          label: "Project Name",
          placeholder: "Referentiel App",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },
        {
          name: "description",
          label: "Description",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
            rules.max(300, "Your description must not exceed 300 characters"),
          ],
        },
        {
          name: "template",
          label: "Template",
          hint: "You can create from template",
          type: "Select",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          select: {
            store: {
              action: this.getTemplates,
              params: {
                withAuthMiddleware: true,
                $axios: this.$axios,
                project: this.$route.params.id,
              },
              getter: this.templates,
              fieldName: "name",
            },
          },
        },
        {
          name: "workspace",
          label: "Workspace",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: false,
          existForAdd: true,
          icon: "mdi-newspaper",
          select: {
            store: {
              action: this.getWorkspaces,
              params: {
                // $axios: this.$axios,
                // project: this.$route.params.id,
                // isActor:true
              },
              getter: this.workspaces,
              fieldName: "name",
            },
          },
        },
        {
          name: "sep-backend",
          label: "Global Backend Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "backendType",
          label: "Backend Type",
          type: "Select",
          col: 12,
          md: 6,

          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: methods.getBackendType(false),
          },
          icon: "mdi-nodejs",
        },

        {
          name: "port",
          label: "Backend Server Port",
          type: "Number",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-router-network",
        },
        {
          name: "databaseType",
          label: "Database Type",
          type: "Select",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: methods.getDatabaseType(false),
          },
          icon: "mdi-database",
        },
        {
          name: "database",
          label: "Database Name",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-database-cog",
        },
        {
          name: "databaseUrl",
          label: "Database Connexion Url",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-database-sync",
        },
        {
          name: "additional-sep-backend",
          label: "Useful Backend config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "mailHost",
          label: "Project Mail Host",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-gmail",
        },
        {
          name: "mailPort",
          label: "Project Mail Port",
          type: "Number",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-mailbox-outline",
        },
        {
          name: "mailAddresse",
          label: "Project Mail Addresse",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-email-outline",
        },
        {
          name: "mailPassword",
          label: "Project Mail Password",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-email-lock",
        },
        {
          name: "sep-frontend",
          label: "Global Frontend Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "frontendType",
          label: "Frontend Type",
          type: "Select",
          col: 12,
          md: 6,

          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: methods.getFrontendType(false),
          },
          icon: "mdi-vuejs",
        },
        {
          name: "frontendCssFramework",
          label: "Frontend CSS Framework",
          type: "Select",
          col: 12,
          md: 6,

          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: methods.getFrontendCssFramework(false),
          },
          icon: "mdi-vuetify",
        },

        {
          name: "sep-backend",
          label: "Project Additional Info",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "image",
          label: "Project Image",
          type: "File",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-upload",
        },
        // {
        //   name: "images",
        //   label: "Project Image",
        //   type: "Dropzone",
        //   col: 12,
        //   md: 6,
        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   icon: "mdi-email-outline",
        // },
      ];
    },
    profileFields() {
      return [
        {
          name: "lastName",
          label: "Last Name",
          placeholder: "Last Name",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },
        {
          name: "firstName",
          label: "First Name",
          placeholder: "First Name",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
            rules.max(300, "Your first name must not exceed 300 characters"),
          ],
        },

        {
          name: "sep-backend",
          label: "Change Password",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "newPassword",
          label: "New Password",
          placeholder: "Password",
          type: "Password",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
            // rules.max(300, "Your first name must not exceed 300 characters"),
          ],
        },
        {
          name: "oldPassword",
          label: "Old Password",
          placeholder: "Password",
          type: "Password",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
          rules: [
            // rules.max(300, "Your first name must not exceed 300 characters"),
          ],
        },



        {
          name: "sep-backend",
          label: "Change avatar",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "avatar",
          label: "Profile Image",
          type: "File",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-account-circle",
        },
        // {
        //   name: "images",
        //   label: "Project Image",
        //   type: "Dropzone",
        //   col: 12,
        //   md: 6,
        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   icon: "mdi-email-outline",
        // },
      ];
    },
  },
  methods: {
    ...mapActions(["getUserProfile", "getMyShopingCart", "getWorkspaces", "getActiveSubscription"]),
    goTo(link) {
      this.$router.push(link);

    },
    async initialize() {
      try {
        this.loading = true;
        await this.getUserProfile();
        await this.getMyShopingCart();
        await this.getActiveSubscription()
      } catch (error) {
        console.log("initialize ", error);
        methods.dispatchError(this.$store);
      } finally {
        this.loading = false;
        this.projectModel = {};
        this.callBackProject = null;
        this.showAddProject = false;
        this.profileDialog = false;
        this.profileModal = {};

        if (!this.userProfile.gitLabId && !this.alreadyReminderUserAboutGitLab) {
          this.alreadyReminderUserAboutGitLab = true
          this.manageShowProfile()
        }

      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    manageShowProfile() {
      this.profileModel = Object.assign({}, this.userProfile)
      delete this.profileModel.avatar
      delete this.profileModel.password
      this.profileDialog = !this.profileDialog;
    },
    manageProfileDialog() {
      this.callBackProfile = updateProfile

      this.profileModel = Object.assign({}, this.userProfile)
      delete this.profileModel.avatar
      delete this.profileModel.password
      this.profileDialog = !this.profileDialog;
    },
    async signOut() {
      try {
        this.loading = true;
        await logout();
        methods.dispatchSuccess(this.$store);
      } catch (error) {
        console.log("signout error ", error);
        methods.dispatchError(this.$store);
      } finally {
        this.loading = false;
      }
    },
    addProject() {
      this.showAddProject = !this.showAddProject;
      this.callBackProject = createProject;
      this.projectModel = {};
    },
    onSuccess() {

      this.initialize();
      this.$router.push("/");
    },


  },
  mounted() {
    this.initialize();
    // this.initSocketIo()
  },
};
</script>
<style>
#App {
  font-size: 0.9rem;
}

.main {
  color: #6b6b6b;
}

.bg-main {
  color: #d7d6d6;
}

.btn {
  text-transform: unset !important;
}

.m-icon {
  font-weight: 100;
  font-size: 0.3rem;
}

.neoclass {
  text-decoration: none;
  position: absolute;
  border: none;
  font-size: 0.9rem;
  font-weight: 900;
  font-family: inherit;
  color: #fff;
  width: 8em;
  height: 1.7rem;
  line-height: 2rem;
  text-align: center;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 300%;
  border-radius: 30px;
  z-index: 1;
}

.neoclass:hover {
  animation: ani 8s linear infinite;
  border: none;
}

@keyframes ani {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

.neoclass:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
}

.neoclass:hover::before {
  filter: blur(20px);
}

.neoclass:active {
  background: linear-gradient(32deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
}

.logo {
  width: 9em;
  height: 2rem;
  background: linear-gradient(to top, #00154c, #12376e, #23487f);
  color: #fff;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: 20px 20px 40px #ededed, -20px -20px 40px #ffffff;
  overflow: hidden;
}

.logo span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: top 0.5s;
}

/* avatar */

.userName {
  font-size: 0.85rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

/* profile */

.m-card {
  /* width: 340px; */
  height: 150px;
  background: whitesmoke;
  transition: 0.2s linear;
  box-shadow: 1px 1px 10px lightslategray;
  cursor: default;
}

.m-card-top-part {
  width: 100%;
  height: 70%;
  padding: 15px;
  display: flex;
}

.m-left-part {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.right-part {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.m-user-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.m-name {
  font-size: 24px;
}

.m-role {
  font-size: 10px;
  color: lightcyan;
  font-weight: bold;
  text-transform: lowercase;
  background-color: lightskyblue;
  padding: 4px 10px;
  border-radius: 15px;
}

.m-position {
  color: grey;
}

.m-card-bottom-part {
  width: 100%;
  height: 30%;
  display: flex;
  border: 1px solid rgb(231, 227, 227);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.user-photo {
  width: 4.5rem;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid rgb(231, 227, 227);
  box-shadow: 2px 2px 10px lightslategray;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  width: 100%;
}

.bottom-part {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bottom-part:hover>.link {
  transform: scale(1.1);
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  gap: 5.5px;
  font-weight: bold;
  transition: 0.2s linear;
}

.icon {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-part:first-child {
  border-right: 1px solid rgb(231, 227, 227);
}

.m-btn {
  display: inline-block;
  min-width: 150px;
  max-width: 2000px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #9e9ea7;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in;
  z-index: 1;
}

.m-btn::before,
.m-btn::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  transform: skew(15deg);
  transition: all 0.5s;
  overflow: hidden;
  z-index: -1;
}

.m-btn::before {
  left: -10px;
  background: #240046;
}

.m-btn::after {
  right: -10px;
  background: #5a189a;
}

.m-btn:hover::before,
.m-btn:hover::after {
  width: 58%;
}

.m-btn:hover span {
  color: #e0aaff;
  transition: 0.3s;
}

.m-btn:hover span>* {
  color: #e0aaff !important;
  transition: 0.3s;
}

.m-btn span {
  color: #787881;
  font-size: 16px;
  transition: all 0.3s ease-in;
}

.m-btn-disabled {
  display: inline-block;
  min-width: 150px;
  max-width: 2000px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #9e9ea7;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in;
  z-index: 1;
  cursor: not-allowed;
}

.m-btn-disabled::before,
.m-btn-disabled::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  transform: skew(15deg);
  transition: all 0.5s;
  overflow: hidden;
  z-index: -1;
}

.m-btn-disabled::before {
  left: -10px;
  background: transparent !important;
}

.m-btn-disabled::after {
  right: -10px;
  /* background: #5a189a; */
  background: transparent !important;

}

.m-btn-disabled:hover::before,
.m-btn-disabled:hover::after {
  width: 58%;
}

.m-btn-disabled:hover span {
  color: #787881 !important;
  transition: 0.3s;
}

.m-btn-disabled:hover span>* {
  color: #787881 !important;
  transition: 0.3s;
}

.m-btn-disabled span {
  color: #787881 !important;
  font-size: 16px;
  transition: all 0.3s ease-in;
}


.m-fixbtn {
  display: inline-block;
  min-width: 170px;
  max-width: 2000px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #9e9ea7;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in;
  z-index: 1;
}

.m-fixbtn::before,
.m-fixbtn::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  transform: skew(15deg);
  transition: all 0.5s;
  overflow: hidden;
  z-index: -1;
}

.m-fixbtn::before {
  left: -10px;
  background: #240046;
}

.m-fixbtn::after {
  right: -10px;
  background: #5a189a;
}

.m-fixbtn::before,
.m-fixbtn::after {
  width: 58%;
}

.m-fixbtn span {
  color: #e0aaff;
  transition: 0.3s;
}

.m-fixbtn span>* {
  color: #e0aaff !important;
  transition: 0.3s;
}

.m-fixbtn span {
  /* color: #787881; */
  font-size: 16px;
  transition: all 0.3s ease-in;
}

.search-group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 290px;
}

.search-input {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid #9e9ea7;
  border-radius: 8px;
  outline: none;
  background-color: #ffffff;
  color: #0d0c22;
  transition: 0.3s ease;
}

.search-input::placeholder {
  color: #9e9ea7;
}

.search-input:focus,
search-input:hover {
  outline: none;
  border-color: #0d0c2259;
  background-color: #fff;
  box-shadow: 0 0 0 4px #3930e959;
}

.search-icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}

@media screen and (max-width: 600px) {
  .m-btn {
    display: inline-block;
    min-width: 95px;
    max-width: 2000px;
    height: 40px;


  }

  .m-btn * {
    font-size: .8rem !important;


  }
}
</style>