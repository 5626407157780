<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-container v-if="userProfile && userProfile._id" class="white pb-12" fluid>
        <div class="py-5 setting-container scrollbar-style-14 w-100 px-5">
          <div class="d-flex">
            <v-btn class="mr-5" icon @click="closeDialog">
              <v-icon class="font-weight-bold" size="1rem">mdi-close</v-icon>
            </v-btn>
            <h3 class="big-title">Profile</h3>
          </div>

          <div class="container-fluid">
            <v-row class="px-md-12 mt-5 rounded py-md-8">
              <v-col cols="12" md="4">
                <div class="d-flex">
                  <div class="mr-5">
                    <v-avatar size="10rem">
                      <v-img :src="userProfile.avatar"></v-img>
                    </v-avatar>
                  </div>
                  <div>
                    <h3 class="big-title mb-0">
                      {{ userProfile.lastName }} {{ userProfile.firstName }}
                    </h3>
                    <div>
                      <p class="mb-0 p-description secondary--text">
                        {{ userProfile.username }}
                      </p>
                      <div class="mt-3">
                        <span v-if="userProfile.gitLabId" class="mr-3"
                          ><v-icon color="red" small>mdi-gitlab</v-icon> Gitlab
                          </span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
              
              </v-col>

              <v-col cols="12" md="4">
                <div>
                  <logo />
                  <div class="px-3">
                    <h3 class="h5-title mt-3 mb-1 white--text">By Doctor Mavix</h3>
                    <!-- <p class="h5-description">All right Reserve</p> -->
                  </div>
                </div>
              </v-col>
            </v-row>

            <div class="dash w-100 pb-12 rounded">
              <v-row>
                <v-col cols="12" md="6" order="2" order-md="1">
                  <editor-x
                    editorClass="elevation-0"
                    @success="onSuccess"
                    :fields="profileFields"
                    :callback="callBackProfile"
                    :model="profileModel"
                    title="Edite Profile"
                    :withoutImages="false"
                    submitText="Update profile"
                  />
                </v-col>
                <v-col cols="12" md="6" order="1" order-md="2">
                  <v-card
                    class="px-5 py-5 scrollbar-style-14 elevation-0"
                    color="white"
                  >
                    <div class="container">
                      <div class="h3-title mb-8">
                        {{ "Developper Account" }}
                      </div>

                      <div>
                        <separator
                          :label="'Gitlab Account'"
                          size="12px"
                          weight="600"
                          width="180px"
                          :align="'left'"
                          type="left"
                        />
                        <div>
                          <v-autocomplete
                            v-model="gitLabId"
                            :items="items"
                            :loading="isLoading"
                            :search-input.sync="search"
                            chips
                            clearable
                            hide-details
                            hide-selected
                            item-text="username"
                            item-value="id"
                            label="Search gitlab account ..."
                            solo
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  Can not find this
                                  <strong>User</strong>
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                            <template
                              v-slot:selection="{ attr, on, item, selected }"
                            >
                              <v-chip
                                v-bind="attr"
                                :input-value="selected"
                                color="blue-grey"
                                class="white--text"
                                v-on="on"
                              >
                                <v-icon left> mdi-gitlab </v-icon>
                                <span v-text="item.name"></span>
                              </v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-avatar
                                color="indigo"
                                class="text-h5 font-weight-light white--text"
                              >
                                <!-- <v-avatar size="10rem"> -->
                                <v-img :src="item.avatar_url"></v-img>
                                <!-- </v-avatar> -->
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="item.name"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-text="item.username"
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-icon>mdi-gitlab</v-icon>
                              </v-list-item-action>
                            </template>
                          </v-autocomplete>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-container>


    </v-dialog>

    <v-dialog width="unset" v-model="confirmCloseWithoutGitlabDialog">
      <v-alert class="white my-0 py-5" text dense color="read" icon="mdi-alert" border="left">
            <p class="">{{ "Do you really want to close without linking your GitLab account to your profile? " }}</p>
            <v-divider class="my-4 read" style="opacity: 0.22"></v-divider>

            <div>
                <div>
                    <p class="mb-0">
                        {{ "OrangCode will add this GitLab account to all your workspaces and personal projects, as well as to those to which you will be invited to work on this platform." }}
                    </p>
                </div>
            </div>
            <div class="d-flex justify-end mt-5">
                <v-btn color="read" class="mr-2"  outlined @click="confirmCloseWithoutGitlabDialog = false">{{ "Cancel" }}</v-btn>
                <v-btn color="red"   outlined @click="dialog = false ;  confirmCloseWithoutGitlabDialog = false">{{ "Close" }}</v-btn>
            </div>
        </v-alert>
    </v-dialog>

  </div>
</template>
  
  <script>
import {
  mapGetters,
  // mapActions
} from "vuex";
import Logo from "./Logo.vue";
import { updateProfile, deleteProject } from "@/api/user.js";

import { methods, rules } from "@/utils/constant.js";
import EditorX from "../universal/EditorX.vue";
import Separator from "../universal/Separator.vue";

export default {
  components: { Logo, EditorX, Separator },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    profileModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["userProfile"]),
    profileFields() {
      return [
        {
          name: "lastName",
          label: "Last Name",
          placeholder: "Your lastname",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },
        {
          name: "firstName",
          label: "First Name",
          placeholder: "Your firstname",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
            rules.max(300, "Your first name must not exceed 300 characters"),
          ],
        },

        {
          name: "sep-backend",
          label: "Change Password",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "newPassword",
          label: "New Password",
          placeholder: "Your password",
          type: "Password",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
            // rules.max(300, "Your first name must not exceed 300 characters"),
          ],
        },
        {
          name: "oldPassword",
          label: "Old Password",
          placeholder: "Your old password",
          type: "Password",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
          rules: [
            // rules.max(300, "Your first name must not exceed 300 characters"),
          ],
        },

        {
          name: "sep-backend",
          label: "Change avatar",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "avatar",
          label: "Profile Image",
          type: "File",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-account-circle",
        },
        {
          name: "gitLabId",
          label: "",
          type: "Static",
          col: 0,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          value: this.gitLabId,
        },

        // {
        //   name: "images",
        //   label: "Project Image",
        //   type: "Dropzone",
        //   col: 12,
        //   md: 6,
        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   icon: "mdi-email-outline",
        // },
      ];
    },
  },
  data() {
    return {
      callBackProfile: updateProfile,
      isLoading: false,
      items: [],
      gitLabId: null,
      search: null,
      confirmCloseWithoutGitlabDialog: false,
    };
  },

  watch: {
    dialog() {
      if (this.dialog == false) {
        this.$emit("dialogChange");
      }
    },
    model(val) {
      if (val != null) this.tab = 0;
      else this.tab = null;
    },
    search(val) {
      // Items have already been loaded
      // if (this.items.length > 0) return

      this.isLoading = true;

      // console.log(val)
      // Lazily load input items
      fetch(`https://gitlab.com/api/v4/users?username=${val}`)
        .then((res) => res.clone().json())
        .then((res) => {
          //   console.log(res)
          this.items = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    onSuccess() {
      //   this.initialize()
      this.$emit("success");
    },
    closeDialog() {
      if(!this.userProfile.gitLabId){
        this.confirmCloseWithoutGitlabDialog = true 
      }else{
        this.confirmCloseWithoutGitlabDialog = false 
        this.dialog = false
      }
    },
    async deleteProject() {
      try {
        methods.dispatchDeleteDialog(this.$store, {
          dialog: true,
          what: "Do you really want to delete this project",
          callback: deleteProject,
          data: { id: this.project.project._id },
          successHandler: () => {
            this.onSuccess();
            this.$router.push("/");
          },
          description: "You can no longer reconsider your decision",
        });
      } catch (error) {
        methods.dispatchError(this.$store);
      }
    },
  },
};
</script>
  
  <style>
.setting-container {
  overflow-y: scroll;
  height: 100vh;
}
</style>